@use "variables" as *;

/******* Base styles *******/
video {
  max-width: 100% !important;
}
.highlight-text {
  color: $warn !important;
}
.grecaptcha-badge {
  visibility: hidden;
}
html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: $font-family;
  font-size: 14px;
  line-height: 1.5;
}
a {
  outline: none;
  &:hover {
    text-decoration: none !important;
  }
}
.h-100 {
  height: 100% !important;
}
.w-100 {
  width: 100% !important;
}
.mw-100 {
  max-width: 100%;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-800 {
  font-weight: 700;
}
.fw-900 {
  font-weight: 700;
}
.lh {
  line-height: 27px;
}
.theme-container {
  max-width: $theme-max-width;
  margin: 0 auto;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  pointer-events: auto;
}
.theme-container-no-overflow {
  overflow-x: initial;
}
.light-block.mat-mdc-card {
  background: rgba(0, 0, 0, 0.03) !important;
  box-shadow: none !important;
}
.transition {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
}
.text-truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden !important;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.divider {
  border-top: 1px solid rgba(204, 204, 204, 0.6);
}

// Bootstrap Outline Override
button:focus {
  outline: none !important;
}

.mdc-icon-button .mat-icon.mat-icon-xs,
.mat-icon.mat-icon-xs {
  font-size: 14px;
  line-height: 14px;
  height: 14px;
  width: 14px;
  vertical-align: middle;
}
.mdc-icon-button .mat-icon.mat-icon-sm,
.mat-icon.mat-icon-sm,
.mat-nav-list .mat-list-item .mat-list-icon.mat-icon-sm {
  font-size: 18px;
  line-height: 18px;
  height: 18px;
  width: 18px;
  vertical-align: middle;
}
.mdc-icon-button .mat-icon.mat-icon-lg,
.mat-icon.mat-icon-lg {
  font-size: 36px;
  line-height: 36px;
  height: 36px;
  width: 36px;
  vertical-align: middle;
}
.mdc-icon-button .mat-icon.mat-icon-xlg,
.mat-icon.mat-icon-xlg {
  font-size: 48px;
  line-height: 48px;
  height: 48px;
  width: 48px;
  vertical-align: middle;
}
.mat-icon.caret {
  width: 14px;
}
.mat-mdc-button.flex-row-button .mat-mdc-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.mat-list.reviews .mat-list-item .mat-list-item-content {
  align-items: start;
}
.mat-step-text-label {
  text-transform: uppercase;
}
.product-collection {
  .mat-mdc-tab-list {
    margin: 0 15%;
  }
}
.mdc-tab {
  font-size: 16px;
  padding: 0 16px !important;
  @media screen and (max-width: 767px) {
    height: 36px !important;
    font-family: $font-family-cd;
    font-size: 14px !important;
    color: #000 !important;
  }
}
.mdc-tab__content {
  font-family: $font-family-cd;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  @media screen and (max-width: 767px) {
    font-family: $font-family-cd;
    font-size: 14px !important;
  }
}
.mdc-tab--active {
  color: #000 !important;
  opacity: 1 !important;
}

.filter-sidenav .mat-drawer-inner-container {
  height: auto;
  padding: 2px;
  box-sizing: border-box;
  .mat-expansion-panel {
    border-radius: 0 !important;
  }
}

.line-discount-cart {
  font-size: 11px !important;
  padding: 2px;
  text-align: center;
  border-radius: 5px;
  display: inline-block;
}

.app-dropdown .mat-mdc-menu-item {
  height: 36px;
  line-height: 36px;
  font-size: 14px;
}
.account-icon {
  margin-top: -2px;
  margin-right: 2px;
}
.app-dropdown.account {
  .mat-mdc-menu-content {
    padding: 0;
    .user-info {
      padding: 16px;
      img {
        margin-right: 16px;
        border-radius: 3px;
      }
      .mat-icon {
        margin-top: -2px;
      }
    }
  }
}
.categories-dropdown.mat-mdc-menu-panel {
  max-height: 350px;
}
.categories-dropdown .mat-mdc-menu-item {
  height: 24px;
  line-height: 24px;
  font-size: 14px;
}
.sub-category button {
  padding-left: 30px;
}
.sub-category .sub-category button {
  padding-left: 50px;
}

.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 10px !important;
}
.p-2 {
  padding: 20px !important;
}
.p-3 {
  padding: 30px !important;
}
.py-1 {
  padding: 10px 0 !important;
}
.py-2 {
  padding: 20px 0 !important;
}
.py-3 {
  padding: 30px 0 !important;
}
.py-4 {
  padding: 40px 0 !important;
}
.py-5 {
  padding: 50px 0 !important;
}
.px-1 {
  padding: 0 10px !important;
}
.px-2 {
  padding: 0 20px !important;
}
.px-3 {
  padding: 0 30px !important;
}
.px-4 {
  padding: 0 40px !important;
}
.px-5 {
  padding: 0 50px !important;
}
.pl-3 {
  padding-left: 30px;
}
.m-0 {
  margin: 0 !important;
}
.mt-1 {
  margin-top: 10px !important;
}
.mt-2 {
  margin-top: 20px !important;
}
.mt-3 {
  margin-top: 30px !important;
}
.mr-1 {
  margin-right: 10px !important;
}

.d-block {
  display: block;
}

.info-bar {
  margin-top: 30px;
  .mat-mdc-card {
    height: 100px;
    .content {
      margin-left: 10px;
      p {
        font-weight: 500;
      }
      span {
        font-size: 13px;
      }
    }
  }
}

.social-icon {
  width: 48px;
  height: 48px;
  cursor: pointer;
  fill: #666666;
  outline: none;
}

/******* Toolbar *******/
.top-toolbar.mat-toolbar-row {
  height: 32px;
  font-size: 13px;
  .sidenav-toggle {
    height: 31px;
    line-height: 31px;
    min-width: 24px;
    padding: 0;
    border-radius: 0;
  }
  .top-menu {
    margin-right: 10px;
    text-transform: uppercase;
    a {
      text-decoration: none;
      font-weight: 400;
      font-size: 13px;
      line-height: 31px;
      padding: 0 10px;
      min-width: 70px;
      border-radius: 0;
    }
    a.mobile-menu-icon {
      min-width: 36px;
    }
    .mat-icon.more {
      height: 28px;
    }
  }
}
.flag-menu-title {
  vertical-align: top !important;
  margin-left: 3px;
}
.top-menu-dropdown .mat-mdc-menu-item img {
  vertical-align: middle;
  margin-right: 8px;
}
.cart-dropdown.mat-mdc-menu-panel {
  margin-right: 15px;
  padding: 6px 14px;
  font-size: 13px;
  width: 280px !important;
  .mat-list {
    padding: 0;
    .mat-list-item {
      .mat-list-item-content {
        padding: 0;
        img {
          width: 70px;
          margin-left: 10px;
        }
      }
      .mat-list-text > * {
        font-size: 14px;
        font-weight: 700;
      }
      .mat-line:nth-child(n + 2) {
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
  .cart-dropdown-footer {
    font-size: 14px;
  }
}

.logo-toolbar.mat-toolbar-row {
  height: 80px;
  background-color: $accent;
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 60px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    height: 48px;
  }
}
.logo {
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  color: inherit;
}
.search-form {
  border-radius: 22px;
  overflow: hidden;
  .categories {
    height: 40px;
    width: 180px;
    border-radius: 0;
  }
  input[type="text"] {
    border: 0;
    outline: none;
    padding: 0 34px 0 12px;
  }
  .search-btn {
    margin-left: -20px;
    box-shadow: none !important;
  }
}
.search-dropdown.mat-mdc-menu-panel {
  border-radius: 26px;
  max-width: 100%;
  .mat-mdc-menu-content {
    padding: 0;
  }
}
.top-cart {
  span {
    font-size: 13px;
    font-weight: 400;
    line-height: 1.2;
  }
}

/******* Top navbar (menu) *******/
.top-navbar.mat-toolbar {
  height: 48px;
  padding: 0;
  background-color: $accent;
  a {
    min-width: 70px;
    border-radius: 0;
    text-transform: uppercase;
    padding: 6px 16px;
  }
  button:focus {
    outline: none !important;
  }
}
.cdk-overlay-pane.mega-menu-pane {
  left: 0 !important;
  width: 100%;
  margin-top: 20px;
  .mat-mdc-menu-panel {
    width: 100%;
    max-width: none;
    border-radius: 0;
    background-color: #fff;
    .mat-mdc-menu-content {
      padding: 0;
    }
  }
  .mega-menu-widget {
    position: relative;
    button {
      position: absolute;
      top: 10px;
      left: 10px;
      font-weight: 400;
    }
    .mat-chip-list {
      position: absolute;
      bottom: 10px;
      left: 10px;
    }
  }
}

/******* Carousel *******/
.mat-tab-no-border {
  .mdc-tab {
    position: relative;
  }
  .mat-mdc-tab-header {
    border-bottom: none !important;
  }
}
button.swipe-arrow {
  position: absolute;
  top: 50%;
  margin-top: -22px;
  z-index: 10;
  background-image: none;
}
.swiper-nav {
  .swiper-pagination-bullet {
    color: $dark !important;
    height: 0 !important;
    width: 0 !important;
    padding: 0 5px;
    opacity: 0.5 !important;
  }
  .swiper-pagination-bullet-active {
    background: $dark !important;
    opacity: 1 !important;
  }
}

/******* Product *******/
.swiper-img-container {
  .youtube-player-placeholder {
    width: auto;
    max-width: 100%;
    max-height: 450px;
  }
}
.swiper-thumb {
  opacity: 0.6;
  max-width: 100%;
  max-height: 75px;
}
.swiper-thumb-active {
  opacity: 1 !important;
  img {
    opacity: 1 !important;
  }
}
youtube-player {
  iframe {
    width: 100%;
    max-width: 100%;
  }
}
.product-item {
  background-color: #fff !important;
  padding: 5px !important;
  position: relative !important;
  border-radius: 4px !important;
  margin: 5px !important;
  .mat-chip-list {
    position: absolute;
    top: 10px;
    right: 10px;
    &.list-view {
      left: 10px;
    }
  }
  .series-label {
    position: relative;
    span {
      font-size: 11px;
      position: absolute;
      padding: 0 5px;
      top: 0;
      left: -5px;
      background-color: rgba(255, 255, 255, 0.5);
      font-family: $font-family-cd;
      font-weight: 700;
      display: inline-block;
      z-index: 2;
    }
  }
  .image-link {
    display: block;
    padding: 2%;
  }
  img {
    max-width: 100%;
  }
  .category {
    margin-bottom: 4px;
  }
  .name {
    height: 45px;
    position: relative;
    .sku {
      font-family: $font-family-cd !important;
      font-size: 8px;
      font-weight: 700;
      position: absolute;
      top: -10px;
      right: 0;
      background-color: rgba(255, 255, 255, 0.5);
    }
    .title {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      color: inherit;
      text-decoration: none;
      font-weight: 400;
      font-size: 13px;
      font-family: $font-family-cd !important;
      max-height: 40px;
      text-align: left;
      overflow-y: hidden;
    }
    .ratings-container {
      position: absolute;
      top: -20px;
      left: 0;
      right: 0;
    }
  }
  .prices {
    width: 100%;
    margin: 5px 0;
    text-align: right !important;
    font-family: $font-family-cd;
    min-height: 14px;
    .old-price {
      text-decoration: line-through;
      height: 14px;
      padding: 0 10px 0 0;
      display: inline-flex;
    }
    .new-price {
      font-size: 14px;
      padding: 0 10px 0 0;
      display: inline-flex;
    }
  }
  .quick-view {
    padding: 0 !important;
  }
}
.old-price {
  text-decoration: line-through;
}

/***** ProductFilters & Sort *******/
.filter-list {
  .mat-mdc-tab-body-content {
    overflow: hidden;
  }
  .mat-mdc-checkbox {
    display: flex;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    width: 100%;
    height: 30px;
    .mdc-form-field {
      display: unset !important;
      width: 100%;
      & > label {
        width: 70%;
        text-align: left;
        margin-bottom: 0 !important;
        line-height: 20px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .mat-mdc-radio-button {
    label {
      font-family: $font-family-cd;
      font-weight: 400;
      margin-bottom: 0 !important;
      line-height: 20px;
    }
  }
}

/******* Back to top *******/
.back-to-top {
  position: fixed;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 99999;
  right: 20px;
  bottom: 20px;
  opacity: 0.5;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  &:hover {
    opacity: 0.9;
  }
}

/******* Responsive *******/
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575px) {
  .top-toolbar.mat-toolbar-row {
    padding: 0;
    .top-menu {
      margin-right: 0;
      a {
        min-width: 56px;
      }
    }
    .sidenav-toggle {
      min-width: 52px;
    }
  }
  .search-form input[type="text"] {
    width: 160px;
  }
  .search-form .categories {
    width: 140px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767px) {
  .top-toolbar.mat-toolbar-row {
    padding: 0;
    .top-menu {
      margin-right: 0;
    }
    .sidenav-toggle {
      min-width: 52px;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991px) {
  .top-toolbar.mat-toolbar-row {
    padding: 0;
    .top-menu {
      margin-right: 0;
    }
    .sidenav-toggle {
      min-width: 52px;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 0px;
  &:hover {
    background: #cccccc;
  }
  &:active {
    background: #888888;
  }
}
::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 0px;
  &:hover {
    background: #666666;
  }
  &:active {
    background: #333333;
  }
}
::-webkit-scrollbar-corner {
  background: transparent;
}

@media (min-width: 1380px) {
  .theme-container {
    max-width: 1920px;
  }
}

.sidenav .mat-drawer-inner-container {
  overflow-x: hidden;
}

::ng-deep.mat-mdc-menu-panel {
  max-width: none !important;
}

.side-nav-container {
  .mat-mdc-card {
    height: 100%;
    border-right: 1px solid #ddd;
    box-shadow: none;
    border-radius: 0;
  }
}

.sidenav-top-link {
  display: block;
  text-align: left;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
  span {
    font-weight: 700;
    font-size: 16px;
    width: 100%;
    font-family: $font-family-cd;
    text-transform: uppercase;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
  }
  &:hover {
    background-color: #ddd;
  }
  &.root:hover {
    background-color: unset;
  }
  &.router-link-active {
    background-color: #ddd;
  }
  &.child {
    text-indent: 20px;
  }
  mat-icon {
    position: absolute;
    top: 0;
    left: -15px;
  }
}

.sidenav-link {
  margin: 0 10px;
  text-align: left !important;
  width: 100%;
  display: inline-flex;
  @media screen and (min-width: 768px) {
    &.mat-mdc-button {
      line-height: 24px !important;
    }
  }
  &:hover {
    background-color: #ddd !important;
  }
  span {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
  &.router-link-active {
    background-color: #ddd !important;
  }
  &.child {
    margin: 0 10px 0 15px;
    max-width: 95%;
    position: relative;
    span {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    mat-icon {
      position: absolute !important;
      top: 0 !important;
      left: -5px !important;
    }
  }
}

.products-wrapper {
  padding-right: 10px;
  padding-top: 20px;
  @media screen and (max-width: 768px) {
    padding-top: 15px;
  }
  .col {
    padding: 0 0 10px 10px;
  }
}

.mat-mdc-card.product-image {
  margin-top: 10px;
  padding: 6px;
  text-align: center;
  @media screen and (max-width: 959px) {
    margin-top: 0;
  }
  button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
  }
  img {
    max-width: 100%;
  }
  .mat-chip-list {
    position: absolute;
    top: 10px;
    left: 10px;
    pointer-events: none;
  }
}

.listing-manufacturer {
  max-height: 25px;
  padding: 0;
  margin: 0;
}
.list-manufacturer-name {
  font-family: $font-family-cd;
  font-weight: 700;
  font-size: 8px;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: -15px;
  width: 100%;
}

.h-90 {
  height: 90% !important;
}

.elfa-brand-swiper {
  .swiper-slide {
    text-align: center !important;
  }
}
