.main {
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
}
.nop-content {
  .h1,
  .h2,
  .h3,
  .h4 {
    font-weight: 400 !important;
  }
  .h1 {
    font-size: 28px !important;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    padding: 20px 0;
  }
  .h2 {
    font-size: 22px !important;
    text-transform: uppercase;
  }
  .h3 {
    font-size: 22px !important;
    text-transform: uppercase;
  }
  .h3 {
    font-size: 18px !important;
    text-transform: uppercase;
  }
  .h4 {
    font-size: 22px !important;
    text-transform: unset;
  }
}

