@use "sass:map";
@use "@angular/material" as mat;

$elfa-palette: (
  primary: (
    0: #000000,
    10: #1b1b1b,
    20: #303030,
    25: #3b3b3b,
    30: #474747,
    35: #525252,
    40: #5e5e5e,
    50: #777777,
    60: #919191,
    70: #ababab,
    80: #c6c6c6,
    90: #e2e2e2,
    95: #f1f1f1,
    98: #f9f9f9,
    99: #fcfcfc,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #1b1c1c,
    20: #303030,
    25: #3b3b3b,
    30: #474747,
    35: #525252,
    40: #5f5e5e,
    50: #777777,
    60: #919090,
    70: #acabab,
    80: #c8c6c6,
    90: #e4e2e2,
    95: #f2f0f0,
    98: #fbf9f8,
    99: #fefcfb,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #1a1c1c,
    20: #2f3131,
    25: #3a3c3c,
    30: #464747,
    35: #515353,
    40: #5d5e5f,
    50: #767777,
    60: #909191,
    70: #ababab,
    80: #c6c6c6,
    90: #e3e2e2,
    95: #f1f0f0,
    98: #faf9f9,
    99: #fdfcfc,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #201a1b,
    20: #352f30,
    25: #413a3b,
    30: #4c4546,
    35: #585052,
    40: #645c5e,
    50: #7e7576,
    60: #988e90,
    70: #b3a9aa,
    80: #cfc4c5,
    90: #ebe0e1,
    95: #faeeef,
    98: #fff8f8,
    99: #fffbff,
    100: #ffffff,
    4: #120d0e,
    6: #171213,
    12: #241e1f,
    17: #2f282a,
    22: #3a3334,
    24: #3e3739,
    87: #e3e3e3,
    92: #f1f1f1,
    94: #f7f7f7,
    96: #fdfdfd,
  ),
  neutral-variant: (
    0: #000000,
    10: #242424,
    20: #3a3a3a,
    25: #454545,
    30: #515151,
    35: #5d5d5d,
    40: #6a6a6a,
    50: #838383,
    60: #9e9e9e,
    70: #b9b9b9,
    80: #d5d5d5,
    90: #e4e4e4,
    95: #f0f0f0,
    98: #f8f8f8,
    99: #fbfbfb,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($elfa-palette, secondary),
  neutral: map.get($elfa-palette, neutral),
  neutral-variant: map.get($elfa-palette, neutral-variant),
  error: map.get($elfa-palette, error),
);
$_primary: map.merge(map.get($elfa-palette, primary), $_rest);
$_tertiary: map.merge(map.get($elfa-palette, tertiary), $_rest);

// Create a theme with the specified color type and density.
@function create-theme($type: light, $density: 0) {
  @return mat.define-theme(
    (
      color: (
        theme-type: $type,
        primary: $_primary,
        tertiary: $_tertiary,
        use-system-variables: true,
        system-variables-prefix: sys,
      ),
      typography: (
        plain-family: "Pragati Narrow",
        brand-family: "Fjalla One",
      ),
      density: (
        scale: $density,
      ),
    )
  );
}

// Define the default (light) theme.
$light-theme: create-theme(
  $type: light,
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
@include mat.elevation-classes();
@include mat.app-background();

@mixin theme($light-theme) {
  mat-toolbar.mat-accent {
    background: mat.get-theme-color($light-theme, secondary);
  }
}

// Include the default theme styles.
html {
  @include mat.all-component-themes($light-theme);
  @include mat.system-level-colors($light-theme);
  @include mat.system-level-typography($light-theme);
}

@include mat.color-variants-backwards-compatibility($light-theme);
@include mat.typography-hierarchy($light-theme);

// Create classes for all density scales which are supported by all MDC-based components.
// The classes are applied conditionally based on the selected density in the dev-app layout
// component.
$density-scales: (-1, -2, -3, -4, minimum, maximum);
@each $scale in $density-scales {
  .demo-density-#{$scale} {
    $density-theme: create-theme(
      $density: $scale,
    );
    @include mat.all-component-densities($density-theme);
  }
}

// Raised button overriders
.mat-mdc-raised-button {
  border-radius: 10px !important;
  &.mat-primary {
    background-color: #000 !important;
    color: #fff !important;
  }
  &.mat-accent {
    background-color: #444 !important;
    color: #fff !important;
  }
  &.mat-warn {
    background-color: #7e0007 !important;
    color: #fff !important;
  }
}

// Fab Button overrides
.mat-mdc-mini-fab {
  &.mat-primary {
    background-color: #000 !important;
    color: #fff !important;
  }
  &.mat-accent {
    background-color: #444 !important;
    color: #fff !important;
  }
  &.mat-warn {
    background-color: #7e0007 !important;
    color: #fff !important;
  }
}


// Tone down dialog
.mat-mdc-dialog-surface{
  border-radius: 0 !important;
  background-color: #fff !important;
}

// Slide Toggle
.mdc-switch--unselected .mdc-switch__track::before {
  background-color: #CCCCCC !important;
  border: none !important;
}
