/* You can add global styles to this file, and also import other style files */
@use "app/styles/flex";
@use "app/styles/base";
@use "app/styles/theming";
@use "app/styles/rtl";
@use "swiper/scss";
@use "swiper/scss/autoplay";
@use "swiper/scss/controller";
@use "swiper/scss/effect-coverflow";
@use "swiper/scss/effect-cube";
@use "swiper/scss/effect-fade";
@use "swiper/scss/effect-flip";
@use "swiper/scss/manipulation";
@use "swiper/scss/mousewheel";
@use "swiper/scss/navigation";
@use "swiper/scss/pagination";
@use "swiper/scss/parallax";
@use "swiper/scss/scrollbar";
@use "swiper/scss/thumbs";
@use "swiper/scss/virtual";
@use "swiper/scss/zoom";
@use "ngx-toastr/toastr";
@use "@angular/cdk/overlay-prebuilt.css";
@use "app/styles/nop-content";

.filter-abs {
  .mdc-tab {
    opacity: 1 !important; // For Accessibility
  }
}

.white-text {
  color: #fff !important;
}
.white-form-field {
  .mat-form-field-outline {
    background-color: #fff !important;
  }
}
/** Basic Toastr Colour **/
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
  background-color: #ddd !important;
  color: #000 !important;
}
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
  background-color: #e51b23 !important;
  color: #fff !important;
}
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
  background-color: #000 !important;
  color: #fff !important;
}
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
  background-color: #666 !important;
  color: #fff !important;
}

@media print {
  .hide-print {
    display: none;
  }
}

.elfa-container {
  @media screen and (min-width: 960px) {
    padding: 5px 5%;
  }
  @media screen and (max-width: 959px) {
    padding: 5px 2%;
  }
}

.white-bg {
  background-color: #fff !important;
}

// HACK
.address-create .mat-expansion-panel-body {
  padding: 0 0 16px !important;
}

// NOT blue links ...
a {
  color: #000;
}

// Gallery
gallery {
  background-color: #fff !important;
}
gallery-item {
  background-color: #fff !important;
}
gallery-thumbs {
  background-color: #fff !important;
  & .g-slider {
    left: 60px !important;
  }
}
.swiper-slide {
  max-width: 100%;
  margin: auto 0;
  img {
    max-width: 100%;
  }
}

// Slider Overrides (always show the badge for Zooming and dealer locator)
.mat-slider-thumb-label {
  transform: rotate(45deg) !important;
  border-radius: 50% 50% 0 !important;
}

.mat-slider-thumb {
  transform: scale(0) !important;
}

.mat-slider-thumb-label-text {
  opacity: 1 !important;
}
