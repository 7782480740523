@use 'sass:math';
$aspect-ratio-padding: 100%;
$aspect-ratio: math.div(1, 1);
$font-family: 'Pragati Narrow', sans-serif;
$font-family-cd: 'Fjalla One', sans-serif;
$theme-max-width: 1920px;

$theme-colors: (
  "primary": #020202,
  "danger": #e51b23
);
$primary: #020202;
$primary-light: #323232;

$background-color: #f0f0f0;

$accent: #444444;
$warn: #890606;
$dark: #26272A;
$medium: #c0c0c0;
$dark-text: #26272A;
$light: #f0f0f0;
$extra-light: #f6f6f6;
$main-background: #999999;
$rating-star: #ecd717;
$breadcrumb: #666666;

$small-width-max: 599px;
$small-width-min: 600px;
$medium-width-max: 959px;
$medium-width-min: 960px;
$large-width-max: 1279px;
$large-width-min: 1280px;
