.flex-5 {
  flex: 1 1 5%;
  box-sizing: border-box;
  max-width: 5% !important;
}
.flex-7 {
  flex: 1 1 7%;
  box-sizing: border-box;
  max-width: 7% !important;
}
.flex-10 {
  flex: 1 1 10%;
  box-sizing: border-box;
  max-width: 10% !important;
}
.flex-15 {
  flex: 1 1 15%;
  box-sizing: border-box;
  max-width: 15% !important;
}
.flex-16 {
  flex: 1 1 16%;
  box-sizing: border-box;
  max-width: 16% !important;
}
.flex-20 {
  flex: 1 1 20%;
  box-sizing: border-box;
  max-width: 20% !important;
}
.flex-25 {
  flex: 1 1 25%;
  box-sizing: border-box;
  max-width: 25% !important;
}
.flex-30 {
  flex: 1 1 30%;
  box-sizing: border-box;
  max-width: 30% !important;
}
.flex-33 {
  flex: 1 1 33%;
  box-sizing: border-box;
  max-width: 33% !important;
}
.flex-35 {
  flex: 1 1 35%;
  box-sizing: border-box;
  max-width: 35% !important;
}
.flex-40 {
  flex: 1 1 40%;
  box-sizing: border-box;
  max-width: 40% !important;
}
.flex-45 {
  flex: 1 1 45%;
  box-sizing: border-box;
  max-width: 45% !important;
}
.flex-50 {
  flex: 1 1 50%;
  box-sizing: border-box;
  max-width: 50% !important;
}
.flex-55 {
  flex: 1 1 55%;
  box-sizing: border-box;
  max-width: 55% !important;
}
.flex-60 {
  flex: 1 1 60%;
  box-sizing: border-box;
  max-width: 60% !important;
}
.flex-65 {
  flex: 1 1 65%;
  box-sizing: border-box;
  max-width: 65% !important;
}
.flex-66 {
  flex: 1 1 66%;
  box-sizing: border-box;
  max-width: 66% !important;
}
.flex-70 {
  flex: 1 1 70%;
  box-sizing: border-box;
  max-width: 70% !important;
}
.flex-75 {
  flex: 1 1 75%;
  box-sizing: border-box;
  max-width: 75% !important;
}
.flex-80 {
  flex: 1 1 80%;
  box-sizing: border-box;
  max-width: 80% !important;
}
.flex-85 {
  flex: 1 1 85%;
  box-sizing: border-box;
  max-width: 85% !important;
}
.flex-90 {
  flex: 1 1 90%;
  box-sizing: border-box;
  max-width: 90% !important;
}
.flex-95 {
  flex: 1 1 95%;
  box-sizing: border-box;
  max-width: 95% !important;
}
.flex-100 {
  flex: 1 1 100%;
  box-sizing: border-box;
  max-width: 100% !important;
}

@media (min-width:576px) {
  .flex-sm-5 {
    flex: 1 1 5%;
    box-sizing: border-box;
    max-width: 5% !important;
  }
  .flex-sm-7 {
    flex: 1 1 7%;
    box-sizing: border-box;
    max-width: 7% !important;
  }
  .flex-sm-10 {
    flex: 1 1 10%;
    box-sizing: border-box;
    max-width: 10% !important;
  }
  .flex-sm-15 {
    flex: 1 1 15%;
    box-sizing: border-box;
    max-width: 15% !important;
  }
  .flex-sm-16 {
    flex: 1 1 16%;
    box-sizing: border-box;
    max-width: 16% !important;
  }
  .flex-sm-20 {
    flex: 1 1 20%;
    box-sizing: border-box;
    max-width: 20% !important;
  }
  .flex-sm-25 {
    flex: 1 1 25%;
    box-sizing: border-box;
    max-width: 25% !important;
  }
  .flex-sm-30 {
    flex: 1 1 30%;
    box-sizing: border-box;
    max-width: 30% !important;
  }
  .flex-sm-33 {
    flex: 1 1 33%;
    box-sizing: border-box;
    max-width: 33% !important;
  }
  .flex-sm-35 {
    flex: 1 1 35%;
    box-sizing: border-box;
    max-width: 35% !important;
  }
  .flex-sm-40 {
    flex: 1 1 40%;
    box-sizing: border-box;
    max-width: 40% !important;
  }
  .flex-sm-45 {
    flex: 1 1 45%;
    box-sizing: border-box;
    max-width: 45% !important;
  }
  .flex-sm-50 {
    flex: 1 1 50%;
    box-sizing: border-box;
    max-width: 50% !important;
  }
  .flex-sm-55 {
    flex: 1 1 55%;
    box-sizing: border-box;
    max-width: 55% !important;
  }
  .flex-sm-60 {
    flex: 1 1 60%;
    box-sizing: border-box;
    max-width: 60% !important;
  }
  .flex-sm-65 {
    flex: 1 1 65%;
    box-sizing: border-box;
    max-width: 65% !important;
  }
  .flex-sm-66 {
    flex: 1 1 66%;
    box-sizing: border-box;
    max-width: 66% !important;
  }
  .flex-sm-70 {
    flex: 1 1 70%;
    box-sizing: border-box;
    max-width: 70% !important;
  }
  .flex-sm-75 {
    flex: 1 1 75%;
    box-sizing: border-box;
    max-width: 75% !important;
  }
  .flex-sm-80 {
    flex: 1 1 80%;
    box-sizing: border-box;
    max-width: 80% !important;
  }
  .flex-sm-85 {
    flex: 1 1 85%;
    box-sizing: border-box;
    max-width: 85% !important;
  }
  .flex-sm-90 {
    flex: 1 1 90%;
    box-sizing: border-box;
    max-width: 90% !important;
  }
  .flex-sm-95 {
    flex: 1 1 95%;
    box-sizing: border-box;
    max-width: 95% !important;
  }
  .flex-sm-100 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 100% !important;
  }
}

@media (min-width:768px) {
  .flex-md-5 {
    flex: 1 1 5%;
    box-sizing: border-box;
    max-width: 5% !important;
  }
  .flex-md-7 {
    flex: 1 1 7%;
    box-sizing: border-box;
    max-width: 7% !important;
  }
  .flex-md-10 {
    flex: 1 1 10%;
    box-sizing: border-box;
    max-width: 10% !important;
  }
  .flex-md-15 {
    flex: 1 1 15%;
    box-sizing: border-box;
    max-width: 15% !important;
  }
  .flex-md-16 {
    flex: 1 1 16%;
    box-sizing: border-box;
    max-width: 16% !important;
  }
  .flex-md-20 {
    flex: 1 1 20%;
    box-sizing: border-box;
    max-width: 20% !important;
  }
  .flex-md-25 {
    flex: 1 1 25%;
    box-sizing: border-box;
    max-width: 25% !important;
  }
  .flex-md-30 {
    flex: 1 1 30%;
    box-sizing: border-box;
    max-width: 30% !important;
  }
  .flex-md-33 {
    flex: 1 1 33%;
    box-sizing: border-box;
    max-width: 33% !important;
  }
  .flex-md-35 {
    flex: 1 1 35%;
    box-sizing: border-box;
    max-width: 35% !important;
  }
  .flex-md-40 {
    flex: 1 1 40%;
    box-sizing: border-box;
    max-width: 40% !important;
  }
  .flex-md-45 {
    flex: 1 1 45%;
    box-sizing: border-box;
    max-width: 45% !important;
  }
  .flex-md-50 {
    flex: 1 1 50%;
    box-sizing: border-box;
    max-width: 50% !important;
  }
  .flex-md-55 {
    flex: 1 1 55%;
    box-sizing: border-box;
    max-width: 55% !important;
  }
  .flex-md-60 {
    flex: 1 1 60%;
    box-sizing: border-box;
    max-width: 60% !important;
  }
  .flex-md-65 {
    flex: 1 1 65%;
    box-sizing: border-box;
    max-width: 65% !important;
  }
  .flex-md-66 {
    flex: 1 1 66%;
    box-sizing: border-box;
    max-width: 66% !important;
  }
  .flex-md-70 {
    flex: 1 1 70%;
    box-sizing: border-box;
    max-width: 70% !important;
  }
  .flex-md-75 {
    flex: 1 1 75%;
    box-sizing: border-box;
    max-width: 75% !important;
  }
  .flex-md-80 {
    flex: 1 1 80%;
    box-sizing: border-box;
    max-width: 80% !important;
  }
  .flex-md-85 {
    flex: 1 1 85%;
    box-sizing: border-box;
    max-width: 85% !important;
  }
  .flex-md-90 {
    flex: 1 1 90%;
    box-sizing: border-box;
    max-width: 90% !important;
  }
  .flex-md-95 {
    flex: 1 1 95%;
    box-sizing: border-box;
    max-width: 95% !important;
  }
  .flex-md-100 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 100% !important;
  }
}

@media (min-width:992px) {
  .flex-lg-5 {
    flex: 1 1 5%;
    box-sizing: border-box;
    max-width: 5% !important;
  }
  .flex-lg-7 {
    flex: 1 1 7%;
    box-sizing: border-box;
    max-width: 7% !important;
  }
  .flex-lg-10 {
    flex: 1 1 10%;
    box-sizing: border-box;
    max-width: 10% !important;
  }
  .flex-lg-15 {
    flex: 1 1 15%;
    box-sizing: border-box;
    max-width: 15% !important;
  }
  .flex-lg-16 {
    flex: 1 1 16%;
    box-sizing: border-box;
    max-width: 16% !important;
  }
  .flex-lg-20 {
    flex: 1 1 20%;
    box-sizing: border-box;
    max-width: 20% !important;
  }
  .flex-lg-25 {
    flex: 1 1 25%;
    box-sizing: border-box;
    max-width: 25% !important;
  }
  .flex-lg-30 {
    flex: 1 1 30%;
    box-sizing: border-box;
    max-width: 30% !important;
  }
  .flex-lg-33 {
    flex: 1 1 33%;
    box-sizing: border-box;
    max-width: 33% !important;
  }
  .flex-lg-35 {
    flex: 1 1 35%;
    box-sizing: border-box;
    max-width: 35% !important;
  }
  .flex-lg-40 {
    flex: 1 1 40%;
    box-sizing: border-box;
    max-width: 40% !important;
  }
  .flex-lg-45 {
    flex: 1 1 45%;
    box-sizing: border-box;
    max-width: 45% !important;
  }
  .flex-lg-50 {
    flex: 1 1 50%;
    box-sizing: border-box;
    max-width: 50% !important;
  }
  .flex-lg-55 {
    flex: 1 1 55%;
    box-sizing: border-box;
    max-width: 55% !important;
  }
  .flex-lg-60 {
    flex: 1 1 60%;
    box-sizing: border-box;
    max-width: 60% !important;
  }
  .flex-lg-65 {
    flex: 1 1 65%;
    box-sizing: border-box;
    max-width: 65% !important;
  }
  .flex-lg-66 {
    flex: 1 1 66%;
    box-sizing: border-box;
    max-width: 66% !important;
  }
  .flex-lg-70 {
    flex: 1 1 70%;
    box-sizing: border-box;
    max-width: 70% !important;
  }
  .flex-lg-75 {
    flex: 1 1 75%;
    box-sizing: border-box;
    max-width: 75% !important;
  }
  .flex-lg-80 {
    flex: 1 1 80%;
    box-sizing: border-box;
    max-width: 80% !important;
  }
  .flex-lg-85 {
    flex: 1 1 85%;
    box-sizing: border-box;
    max-width: 85% !important;
  }
  .flex-lg-90 {
    flex: 1 1 90%;
    box-sizing: border-box;
    max-width: 90% !important;
  }
  .flex-lg-95 {
    flex: 1 1 95%;
    box-sizing: border-box;
    max-width: 95% !important;
  }
  .flex-lg-100 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 100% !important;
  }
}

@media (min-width:1200px) {
  .flex-xl-5 {
    flex: 1 1 5%;
    box-sizing: border-box;
    max-width: 5% !important;
  }
  .flex-xl-7 {
    flex: 1 1 7%;
    box-sizing: border-box;
    max-width: 7% !important;
  }
  .flex-xl-10 {
    flex: 1 1 10%;
    box-sizing: border-box;
    max-width: 10% !important;
  }
  .flex-xl-15 {
    flex: 1 1 15%;
    box-sizing: border-box;
    max-width: 15% !important;
  }
  .flex-xl-16 {
    flex: 1 1 16%;
    box-sizing: border-box;
    max-width: 16% !important;
  }
  .flex-xl-20 {
    flex: 1 1 20%;
    box-sizing: border-box;
    max-width: 20% !important;
  }
  .flex-xl-25 {
    flex: 1 1 25%;
    box-sizing: border-box;
    max-width: 25% !important;
  }
  .flex-xl-30 {
    flex: 1 1 30%;
    box-sizing: border-box;
    max-width: 30% !important;
  }
  .flex-xl-33 {
    flex: 1 1 33%;
    box-sizing: border-box;
    max-width: 33% !important;
  }
  .flex-xl-35 {
    flex: 1 1 35%;
    box-sizing: border-box;
    max-width: 35% !important;
  }
  .flex-xl-40 {
    flex: 1 1 40%;
    box-sizing: border-box;
    max-width: 40% !important;
  }
  .flex-xl-45 {
    flex: 1 1 45%;
    box-sizing: border-box;
    max-width: 45% !important;
  }
  .flex-xl-50 {
    flex: 1 1 50%;
    box-sizing: border-box;
    max-width: 50% !important;
  }
  .flex-xl-55 {
    flex: 1 1 55%;
    box-sizing: border-box;
    max-width: 55% !important;
  }
  .flex-xl-60 {
    flex: 1 1 60%;
    box-sizing: border-box;
    max-width: 60% !important;
  }
  .flex-xl-65 {
    flex: 1 1 65%;
    box-sizing: border-box;
    max-width: 65% !important;
  }
  .flex-xl-66 {
    flex: 1 1 66%;
    box-sizing: border-box;
    max-width: 66% !important;
  }
  .flex-xl-70 {
    flex: 1 1 70%;
    box-sizing: border-box;
    max-width: 70% !important;
  }
  .flex-xl-75 {
    flex: 1 1 75%;
    box-sizing: border-box;
    max-width: 75% !important;
  }
  .flex-xl-80 {
    flex: 1 1 80%;
    box-sizing: border-box;
    max-width: 80% !important;
  }
  .flex-xl-85 {
    flex: 1 1 85%;
    box-sizing: border-box;
    max-width: 85% !important;
  }
  .flex-xl-90 {
    flex: 1 1 90%;
    box-sizing: border-box;
    max-width: 90% !important;
  }
  .flex-xl-95 {
    flex: 1 1 95%;
    box-sizing: border-box;
    max-width: 95% !important;
  }
  .flex-xl-100 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 100% !important;
  }
}
